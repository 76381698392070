<template>

  <div id="imagediv">
<!--
    <iframe id="ytplayer" type="text/html" width="100%"
            :src="this.getLink(source)"
    />
-->
   <!-- <embed width="100%"
            src="https://www.youtube.com/embed/oMlN3Yttg44?controls=0"
    />-->

    <!--<vytia-player ytid="oMlN3Yttg44" ref="yt" @ready="onPlayerReady"></vytia-player>-->


    <youtube  v-if="showvideo" :video-id="this.getId(this.source)" :player-vars="playerVars" ref="youtube" width="100%"></youtube>

    <i v-else class="fa fa-youtube-play" style="font-size:48px;color:red"  @click="()=>{this.showvideo = !showvideo}"></i>

</div>

</template>

<script>
export default {
  name: 'VideoComponents',
  props: {
    'source': String,
  },
  data() {
    return {
      showvideo: !this.$device.mobile,  //show video preview if not mobile device. For Mobile device show a button and by click the video preview.
         playerVars: {
        autoplay: 0,
        controls: 0,
           vq: 'small',
           origin: ()=>window.location.origin
      }
    }
  },
  methods: {

    getId(src) {

      //let redirect_link = "youtube.com/embed/"

      let id = ''


      if(src) {
        /*
        src = src.trim()

        if (src.indexOf(' ') !== -1) {
          src = src.substring(0, src.indexOf(' '))
        }
        if (src.indexOf('&') !== -1) {
          src = src.substring(0, src.indexOf('&')).trim()
        }
        src = src.replace("m.youtube.com", 'youtube.com');
        src = src.replace("youtu.be", "youtube.com");
        src = src.replace("youtube.com", redirect_link);
        src = src.replace("watch?v=", "/");
        src = src + '?controls=0&origin=' + window.location.origin
        ///console.log(src)
*/

        src = src.trim()

        if (src.indexOf(' ') !== -1) {
          src = src.substring(0, src.indexOf(' '))
        }
        if (src.indexOf('&') !== -1) {
          src = src.substring(0, src.indexOf('&')).trim()
        }
        src = src.replace("m.youtube.com", 'youtube.com');

        id = this.$youtube.getIdFromUrl(src)

      }
      return id
    },

    onPlayerReady () {
      // you have access to the ref here


      // you can see the full list of methods available here
      // https://developers.google.com/youtube/iframe_api_reference?hl=fr#Playback_controls
    }
  },


}
</script>

<style>


@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';


#imagediv {
  width: 100%;
  text-align: center;
}
</style>
