<template>
<div id="privacy-content">
  <h3> Informativa sulla privacy </h3>
  <br/>
  <h4> Informazioni generali </h4>
  <p>
    Le seguenti informazioni forniscono una semplice panoramica di ciò che accade con i tuoi dati personali
    quando visiti questo sito web. Per dati personali, si intendono i dati con cui è possibile essere identificati.
    Di seguito vengono fornite informazioni dettagliate sul tema "Protezione dati"
  </p>

  <h4> Memorizzazione automatica dei dati </h4>
  <p> Quando visiti siti web nella vita quotidiana, alcune informazioni vengono create e archiviate automaticamente dai Server (macchine che ospitano il sito web). Ciò accade
    anche per questo sito web. </p>
  <p> Se visiti questo sito web come stai facendo adesso, il server web salva automaticamente dati come </p>
  <ul>
    <li> l'indirizzo (URL) della pagina visitata </li>
    <li> Browser e versione del browser </li>
    <li> il sistema operativo utilizzato </li>
    <li> il nome dell' host e l'indirizzo IP del dispositivo da cui si effettua l'accesso </li>
    <li> Tipo di dispositivo (Mobile o PC) </li>
    <li> Data e ora </li>
  </ul>
  <p> Questi dati vengono periodicamente cancellati e non trasmessi a terzi. Non si possono comunque escludere controlli da parte di autorità in caso di comportamenti illegali. <br/>
    La base giuridica è secondo l'<a
        href = "https://gdpr-text.com/it/read/article-6/"
        target = "_ blank" rel = "noopener">articolo 6 comma 1 (f) del GDPR </a> (liceità del trattamento) in quanto
    <u>Esiste</u> un legittimo interesse a garantire il funzionamento privo di errori di questo sito Web raccogliendo
    le suddette informazioni nei file di log del server. </p>
  <br />

  <h4>Cookies</h4>
  Questo sito web utilizza i cookie HTTP per salvare dati specifici forniti dall'utente.
  Di seguito viene spiegato cosa sono i cookie e perché vengono utilizzati.

  <h4> Cosa sono esattamente i cookie? </h4>
  <p> Per navigare in Internet, utilizzi un software specifico (Browser). I browser più noti sono ad esempio
    Chrome, Safari, Firefox, Internet Explorer e Microsoft Edge.
    La maggior parte dei siti Web salva piccoli file di testo con informazioni come la lingua preferita o altre
    impostazioni della pagina personale nel tuo browser. Questi file sono chiamati cookie.</p>
  <p> Se visiti nuovamente lo stesso sito Web in un secondo momento, il browser trasmetterà
    queste informazioni nuovamente al sito web, ospitato sul server al fine di poter utilizzare le impostazioni individuali adatte a te

    I cookie possono contenere una varietà di informazioni che rendono il visitatore identificabile personalmente (come ad esempio
    Il tuo nome, indirizzo, indirizzo e-mail o numero di telefono). Tuttavia, un sito Web ha accesso solo a
    dati personali che fornisci tu stesso e non può nemmeno accedere ad altri file sul tuo computer.
  </p>
  <p> I cookie possono essere creati anche da siti web partner (cookie di terze parti). I cookie non sono
    Software e non contengono virus o altra roba "dannosa"

<it-cookie-config />

  <h4>Utilizzo di video</h4>
  <p>Questo sito web contiene almeno un plug-in di YouTube, appartenente a Google Inc., con sede a San Bruno - California, USA.
  L' accesso ai video su questo sito avviene tramite youtube.com.
  Quando si richiamano le pagine interessate, vengono trasmessi l'indirizzo IP e gli altri dati menzionati sopra
  nel paragrafo: "Memorizzazione automatica dei dati", in particolare, viene comunicato quali pagine del sito hai visitato.
  Non appena inizi a riprodurre un video integrato facendo clic su di esso,
  YouTube salverà anche dei cookie.
    Questi cookie possono essere evitati mediante impostazioni ed estensioni del browser appropriate.</p>
  <p><b>Google/YouTube: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland</b></p>
  <p>Protezione dati: <a href="https://policies.google.com/privacy">Link qui</a>,</p>
  <p>Opt-Out: <a href="https://adssettings.google.com/authenticated">Link qui</a>,</p>
  <p> Privacy Shield: <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">Link
    qui</a></p>
  <br/>

  <!--
    <h2> JQuery CDN (jQuery.com) </h2>
    <p> Su questo sito web viene utilizzata la libreria jQuery. Essa consente principalmente un design moderno del sito web. Per aumentare la velocità di caricamento delle pagine, viene utilizzato il CDN (Content Delivery Network) di jQuery. A tal fine, viene stabilita una connessione al server jQuery negli Stati Uniti e il tuo indirizzo IP viene inviato a StackPath LLC, il provider CDN di jQuery.com. </p>
    <p> Sviluppatore della libreria jQuery è il team jQuery della JS Foundation:
      <a href="https://jquery.org/team/"> Team </a>,
      <a href="https://js.foundation/contact"> Contatto </a>. </p>
    <p> Il servizio è fornito da StackPath LLC, 2021 McKinney Ave, Suite 1100, Dallas, TX 75201, USA. </p>
    <p> <a href="https://www.stackpath.com/privacy-statement/#Contact-Information"> Informazioni sulla protezione dei dati presso StackPath LLC </a> </p>
    <p> <b> Periodo di conservazione: </b> </p>
    Vedere la durata di conservazione dei dati nell'ambito dell'uso informativo sopra in questa dichiarazione sulla protezione dei dati.
    Base/i giuridica/i per il trattamento dei dati:
    Il servizio è integrato per ottimizzare questo nostro sito web nel nostro interesse, ai sensi dell'articolo 6 (1) (f) GDPR.
    <p> La trasmissione del tuo indirizzo IP a jQuery (StackPath LLC) negli USA avviene sulla base del <a href="https://www.privacyshield.gov/EU-US-Framework"> EU-US Privacy Shield < /a> </p>
    <br />
-->
  <h4> BootstrapCDN (MaxCDN) </h4>
  <p> Per un design moderno e una presentazione del contenuto ottimizzata per i diversi dispositivi, questo
    Sito web, utilizza la tecnologia bootstrap. Al fine di aumentare la velocità di caricamento delle pagine web, viene utilizzato
    BootstrapCDN (Content Delivery Network)

  <p> <b> StackPath, LLC, 2021 McKinney Avenue, Suite 1100 Dallas, Texas 75201 </b> </p>
  <p> <a href="https://www.stackpath.com/legal/master-service-agreement/#pp"> Protezione dei dati presso StackPath, LLC. </a>
  </p>
  <p> <b> Periodo di conservazione: </b> </p>
  Vedere la durata di conservazione dei dati nell'ambito dell'uso informativo sopra in questa dichiarazione sulla protezione dei dati.
  Base/i giuridica/i per il trattamento dei dati:
  Il servizio è integrato per ottimizzare questo nostro sito web nel nostro interesse, ai sensi dell'articolo 6 (1) (f) GDPR.
  <p> La trasmissione del tuo indirizzo IP a MaxCDN (StackPath LLC) negli USA avviene sulla base del <a href="https://www.privacyshield.gov/EU-US-Framework"> EU-US Privacy Shield </a> </p>
  <br />
 <br />

  <h4> Caratteri web di Google </h4>
  Su questo sito web vengono utilizzati anche Google Web Fonts.
  <p> Il fornitore è Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, che è un rappresentante nell'UE
    Google Dublino, Google Ireland Ltd., Gordon House, Barrow Street, Dublino 4, Irlanda. </p>
  <p> <a href="http://www.google.de/policies/privacy/"> Protezione dei dati presso Google Inc </a> </p>
  <p> <b> Periodo di conservazione: </b> </p>
  Vedere la durata di conservazione dei dati nell'ambito dell'uso informativo sopra in questa dichiarazione sulla protezione dei dati.
  Base/i giuridica/i per il trattamento dei dati:
  Il servizio è integrato per ottimizzare il sito web, ai sensi dell'articolo 6 comma 1 lettera f)
  GDPR.
  <p> Per i casi eccezionali in cui i dati personali (il tuo indirizzo IP) vengono trasferiti negli Stati Uniti,
    Google è soggetto allo <a href="https://www.privacyshield.gov/EU-US-Framework">  Privacy Shield UE-USA </a>. </p>
  <br/>
<br>
  <h2> Il titolare e responsabile del sito web è: </h2>
  <p> <b> Nome: </b> San Giovanni, Daniele Maria </p>
  <p> <h5> <b> Dati di contatto: </b> </h5>
  <p> <b> Email: </b> sangiovanni.daniele@gmail.com </p>
  <p> <b> Numero di cellulare: </b> +49 0157 84 81 62 21 </p>
  <br/><br/><br/>
</div>
</template>

<script>
import ItCookieConfig from "./ItCookieConfig";
export default {
  name: "ItPrivacyContent",
  components: {ItCookieConfig}
}
</script>

<style scoped>
#privacy-content{
  margin-top: 50px;
  padding: 5px;
}
</style>
