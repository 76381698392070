import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import VueGtag from "vue-gtag"
import VueCookies from 'vue-cookies-reactive'
import VuePlayerPlugin from 'vue-youtube-iframe-api'
import VueYoutube from 'vue-youtube'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMeta from 'vue-meta'
import device from "vue-device-detector"

//import '@/assets/main.css';
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-cookies-reactive'))
Vue.use(VueCookies)
Vue.use(VuePlayerPlugin)
Vue.use(VueYoutube)
Vue.use(VueMeta)
Vue.use(device)

// set default config
Vue.$cookies.config('7d')

Vue.config.productionTip = false

// Configuration for Google Analytics
/*Vue.use(VueGtag, {
  config: { id: "275494662" }
});*/

new Vue({
  router:router,
  store:store,
  render: h => h(App)
}).$mount('#app')
