<template>
<div id="privacy-div">
  <div id="close-btn"><b-button pill variant="outline-secondary"  @click="hidePrivacy">{{this.$store.getters.getLanguageStr('close')}}</b-button></div>
  <div></div>
  <de-privacy-content v-if="$store.state.defaultlang==='de'"></de-privacy-content>
  <it-privacy-content v-if="$store.state.defaultlang==='it'"></it-privacy-content>
</div>
</template>

<script>
import ItPrivacyContent from "./ItPrivacyContent";
import DePrivacyContent from "./DePrivacyContent";
export default {
  name: "PrivacyView",
  components: {
    ItPrivacyContent,
    DePrivacyContent
  },
  methods:{
    hidePrivacy() {
      this.$store.commit('SET_SHOWPRIVACYCONTENT', false)
    }
  }
}
</script>

<style scoped>
#privacy-div{
  font-family: Courier, Arial, sans-serif;

  text-align: left !important;
}
#close-btn{
  position: fixed;
  top:20px;
  right:10px;
}
</style>
