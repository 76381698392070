<template>

  <span style="text-decoration: underline; color:blue; cursor:pointer" @click="$store.commit('SET_SHOWPRIVACYCONTENT', true)">
    {{this.$store.getters.getLanguageStr('privacy')}}
  </span>

</template>

<script>
export default {
  name: "PrivacyLink"
}
</script>

<style scoped>

</style>
