<template>
  <div>
    <b-table ref="table"
             :items="songs"
             :fields="fields"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             sort-icon-left
             small
             selectable
             @row-selected="onRowSelected"
             selected-variant=""
             select-mode='single'
    >

    </b-table>

  </div>
</template>

<script>

export default {
  name: 'SongListTable',
  props: {
    'pageId': String
  },
  watch: {
    pageId: function () { // watch it
      this.songs = this.getData()
      this.fields = this.setFields()
    }
  },
  data() {
    return {
      sortBy: 'numb',
      sortDesc: false,
      fields: this.setFields(),
      songs: this.getData()
    }
  },
  mounted() {
 /*   if (this.$store.state.login.isabookselected === false) {
      this.$router.push('/login')
    }*/
  },
  methods: {
    updateFunc() {
      this.$store.dispatch('setSongs')
    },
    onRowSelected(items) {
      this.viewSong(items[0].id)
    },
    setFields() {
      if (this.pageId === '0') {
        return Array(
            {key: 'numb', sortable: true, label: this.$store.getters.getLanguageStr('songnumber')},
          {key: 'title', sortable: true, label: this.$store.getters.getLanguageStr('songtitle')},
          {key: 'groups', label: this.$store.getters.getLanguageStr('songusedfor')},
         // {key: 'status', label: ''}
        )
      } else if (this.pageId === '1') {
        return Array(
            {key: 'numb', label: this.$store.getters.getLanguageStr('songnumber')},
          {key: 'title', label: this.$store.getters.getLanguageStr('songtitle')},
          {key: 'groups', label: this.$store.getters.getLanguageStr('songusedfor')},
          //{key: 'status', label: ''}
        )
      }else if (this.pageId === '2') {
        return Array(
            {key: 'position', label: 'Pos'},
            {key: 'title', label: this.$store.getters.getLanguageStr('songtitle')},
            {key: 'numb', label: this.$store.getters.getLanguageStr('songnumber')},
            {key: 'groups', label: this.$store.getters.getLanguageStr('songusedfor')},
            //{key: 'status', label: ''}
        )
      }
    },
    getData() {
      let songs

      songs = this.$store.state.songs

      songs = songs.filter(function (list_item) {
        return list_item.title != '#'
      })

      if (this.pageId === '1') {

        songs = songs.sort(function (a, b) {
          return a.position - b.position;
        })
        songs = songs.filter(function (list_item) {
          return (list_item.position !== 0)
        })
      } /*else {
        songs = this.$store.state.songs
      }*/
      //return this.$store.state.songs
      return songs
    },
    viewSong(song_id) {
      this.$router.push('/view/' + song_id)
    },
  }
  ,
  computed: {}
}
</script>
