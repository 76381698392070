<template>
  <div id="text" v-if="song">

    <details>
      <summary>
          <span class="title">{{ song.title + " Nr. " + song.numb }} <b-icon icon="info-circle" scale="1" variant="secondary"></b-icon></span>
      </summary>
      <span class="autor">{{ lyric.autor }}</span>
    </details>
    <br/>
    <div class="lyric-div" v-if="lyric.txt" v-html="getLyrics()"/>




  </div>
</template>

<script>
export default {
  name: 'LyricsComponent',
  props: {
    'song': Object,
    'lyric': Object,
  },
  methods: {
    getLyrics() {
      let issue = ''
      issue = this.lyric.txt
      issue = issue.replaceAll('<p>', '')
      issue = issue.replaceAll('</p>', '<br />')

      console.log(issue)
      return issue
    }
  }
}
</script>

<style scoped>
details{
  font-size: 1.3em;
}
.autor{
  font-weight: bold;
  font-style: oblique;
  font-size: 0.7em !important;
  color:gray;
}
.title {
  font-weight: bold;
}
.lyric-div{

}
</style>
