<template>
<span>
  <b-button :click="this.removeAllCookies()">{{$store.getters.getLanguageStr('cookiedeletebtn')}}</b-button>
</span>
</template>

<script>
export default {
  name: "CookieRemoveBtn",
  methods: {
    removeAllCookies(){
      let arr_cookies_keys = this.$cookies.keys()
      for (let i=0; i<arr_cookies_keys.length; i++){
        this.$cookies.remove(arr_cookies_keys[i])
      }
    }
  }
}
</script>

<style scoped>

</style>
