<template>
  <div id="privacy-alert">
  <cookie-law theme="base" v-on:accept="ThankYouMethod()">
    <div slot-scope="props">
      <span v-if="$store.state.defaultlang==='de'">Auf dieser Website werden Cookies verwendet. Weitere Informationen finden Sie in der</span>
      <span v-if="$store.state.defaultlang==='it'">In questo sito vengono utilizzati i cookie. Ulteriori informazioni sono disponibili nell'</span>
      <privacy-link></privacy-link>
      <br/><br/>
    <!-- <span class="btn-content">
        <b-button class="skew" @click="props.close">{{$store.getters.getLanguageStr('ignore')}}</b-button>
           </span>-->
      <span class="btn-content">
        <b-button class="skew" @click="()=>{
          OkClick()
          props.close()
        }"
        >{{ $store.getters.getLanguageStr('accept') }}</b-button>
        </span>
    </div>
  </cookie-law>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import PrivacyLink from "./PrivacyLink";
export default {
  name: "CookieAlert",
  components: { CookieLaw, PrivacyLink },

  methods: {
    ThankYouMethod() {
      console.log('Cookie OK!')
    },
    OkClick(){
console.log('clicked')
      this.$store.commit('SET_COOKIE_ACCEPTED', true)
      this.$cookies.set('okcookie', true);
      this.$cookies.set('lang', this.$store.state.defaultlang);
    }
  }
}
</script>

<style scoped>
#privacy-alert div{
  width: 100%;
  text-align: center;
}
</style>
