<template>
  <div id="app">
    <cookie-alert v-if="!$store.state.cookieaccepted"></cookie-alert>
    <span v-if="$store.state.login.bookcode != '' && !$store.state.showprivacywindow">

    <Navbar2 v-if="this.$device.mobile" />
  <Navbar v-else />

    </span>
    <privacy-view v-if="$store.state.showprivacywindow"></privacy-view>

    <router-view v-else/>
    <br>
    <Footer/>
  </div>
</template>
<script>

import Navbar from './components/Navbar.vue'
import Navbar2 from './components/Navbar2.vue'
import Footer from './components/Footer.vue'
import CookieAlert from "./components/privacy/CookieAlert";
//import Privacy from "./components/PrivacyView";
import PrivacyView from "./components/privacy/PrivacyView";

export default {
  name: 'app',
  components: {PrivacyView, CookieAlert, Navbar, Navbar2, Footer},
  metaInfo() {
    return {
      meta: [
        {name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'}
      ]
    }
  },
  mounted() {
    if (this.$cookies.isKey('okcookie')) {
      this.$store.commit('SET_COOKIE_ACCEPTED', true)
    }
    if (this.$cookies.isKey('lang')) {
      this.$store.commit('SET_DEFAULT_LANG', this.$cookies.get('lang').substr(0, 2))
    }
    document.title = this.$store.getters.getLanguageStr('sitename')
    document.documentElement.setAttribute('lang', this.$store.state.defaultlang)
    document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0");
  },
  methods: {
   /* isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }*/
  }

}

</script>

<style lang="scss">
//@import url('https://fonts.googleapis.com/css?family=Varela+Round');
//@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
body {
  overscroll-behavior-y: contain;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
