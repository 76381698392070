<template>
<div>
  <ul>
    <li
        v-for='song in this.$store.state.songs'
        :key='song.id'
    >
      {{ song.numb }}) {{song.title}} <br>
      <video-component :source="song.video" />

    </li>
  </ul>
</div>
</template>

<script>

import VideoComponent from "../components/VideoComponent";
export default {
  name: "VideoTest",
  components: {VideoComponent}
}
</script>

<style scoped>

</style>
