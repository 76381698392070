import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from "vue-cookies-reactive";
Vue.use(Vuex)
Vue.use(VueCookies)
const Store = new Vuex.Store({
    strict: true,
    state: {
        login: {
            bookcode: '',
            email: '',
            password: '',
            repeatpassword: '',
            'token': '',
            loginerror: false,
            isabookselected: false,
            lastvisitedpage: '/'
        },
        songs: [],
        lyrics: [],
        selectedlyrics: [],
        //apilink: 'http://192.168.0.6/',
        apilink: 'https://www.canti88.it/',
        showprivacywindow: false,
        cookieaccepted: false,
        defaultlang: 'it',

        lang: {
            it: {
                cookiedeletebtn: 'Rimuovere i cookie adesso',
                close: 'Chiudi',
                privacy: 'Informativa sulla privacy',
                accept: 'Accetta',
                ignore: 'Ignora',
                sitename: 'Canti Liturgici',
                songsheet: 'Foglio Canti',
                loginbtn: 'Login',
                logincode: 'Inserire codice',
                fullindex: 'Indice Canti',
                partialindex: 'Canti scelti',
                logout: 'Logout',
                songnumber: 'N° Canto',
                songtitle: 'Titolo',
                songusedfor: 'Uso',
                guest: 'Ospite',
                admin: 'Amministratore',
                email: 'Email',
                password: 'Password',
                errcode: 'Codice non corretto!',
                errnetwork: 'Errore connessione internet!',
            },
            de: {
                cookiedeletebtn: 'Löschen alle Cookies jetzt',
                close: 'Schließen',
                privacy: 'Datenschutzerklärung',
                accept: 'Annehmen',
                ignore: 'Ablehnen',
                sitename: 'Lieder',
                songsheet: 'Liedblatt',
                loginbtn: 'Einloggen',
                logincode: 'Code eingeben',
                fullindex: 'Vollständiges Verzeichnis',
                partialindex: 'Ausgewählte Lieder',
                logout: 'Ausloggen',
                songnumber: 'Liednummer',
                songtitle: 'Titel',
                songusedfor: 'Anlass',
                guest: 'Besucher',
                admin: 'Administrator',
                email: 'E-Mail',
                password: 'Passwort',
                errcode: 'Falscher Code!',
                errnetwork: 'Fehler bei der Internetverbindung!',
            }
        }
    },
    mutations: {
        updateDefaultLang(state, dl) {
            state.defaultlang = dl
        },
        updateBookCode(state, dt) {
            state.login.bookcode = dt
        },
        SET_SONGS(state, songs) {
            state.songs = songs
        },
        SET_LYRICS(state, lyrics){
            state.lyrics.push(lyrics)
        },
        SET_SELECTEDLYRICS(state, lyrics){
            state.selectedlyrics = lyrics
        },
        SET_LOGGED(state) {
            state.login.isabookselected = true
        },

        SET_SHOWPRIVACYCONTENT(state, bl) {
            state.showprivacywindow = bl
        },
        SET_COOKIE_ACCEPTED(state, accept) {
            state.cookieaccepted = accept
        },
        SET_DEFAULT_LANG(state, dl) {
            state.defaultlang = dl
        },
        SET_LAST_VISITED_PAGE(state, lv){
            state.login.lastvisitedpage = lv
        }
    },
    actions: {},
    getters: {
        getLanguageStr: (state) => (str) => {
            return state.lang[state.defaultlang][str]
        },
        getDafaultLang: (state) => () => {
            return state.defaultlang
        },
        getSongs: (state) => (str) => {
            if (str === '0') {
                return state.songs
            }
        },
        getLastVisitedPage: (state) => () => {
                return state.login.lastvisitedpage
        },
        getIsABookSelected: (state) => () => {
            return state.login.isabookselected
        },
        getBookCode: (state) => () => {
            return state.login.bookcode
        },

        apireqlink: (state) => (str) => {
            var lk = ''
            switch (str) {
                case 'songlist':
                    //lk = state.apilink + state.login.bookcode + '/api/songlist'
                    lk = state.apilink + state.login.bookcode + '/api/v2/songs'
                    break
                case 'lyrics':
                    lk = state.apilink + state.login.bookcode + '/api/v2/lyrics'
                    break
                default:
                    break
            }
            return lk
        }

    },
    modules: {}
})

export default Store;
