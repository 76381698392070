<template>
  <div>
    <!-- Tabs with card integration -->
    <b-card no-body>
      <b-tabs small card>
        <b-tab no-body title="Ospite">
          <b-card><LoginForm :admin-user="false" /></b-card>
        </b-tab>
        <b-tab no-body title="Amministratore">
          <b-card no-body><LoginForm :admin-user="true" /></b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
};
</script>
<style scoped>

</style>
