<template>
  <div id="privacy-content">
    <h3>Datenschutzerklärung</h3>
    <br/>
    <h4>Allgemeine Hinweise</h4>
    <span class="paragraph">
      Die folgenden Informationen geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
      passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
      identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz finden Sie weiter unten unter diesem
      Text.
    </span>

    <h4>Automatische Datenspeicherung</h4>
    <span class="paragraph">Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
      auch auf dieser Website.
   Wenn Sie diese Website so wie jetzt gerade besuchen, speichert der Webserver (Computer auf dem diese Website
      gespeichert ist) automatisch Daten wie</span>
    <ul>
      <li>die Adresse (URL) der aufgerufenen Webseite</li>
      <li>Browser und Browserversion</li>
      <li>das verwendete Betriebssystem</li>
      <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
      <li>Gerätetyp (Mobile oder PC)</li>
      <li>Datum und Uhrzeit</li>
    </ul>
    <span class="paragraph">Diese Daten werden regelmäßig gelöscht und nicht weitergegeben, können jedoch nicht ausschließen, dass diese
      Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.<br/>
      Die Rechtsgrundlage besteht nach <a
          href="https://gdpr-text.com/de/read/article-6/"
          target="_blank" rel="noopener">Artikel 6 Absatz 1 (f) DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass
      berechtigtes Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite durch das Erfassen von
      Webserver-Logfiles zu ermöglichen.</span>
    <br/>
    <h4>Cookies</h4>
    Diese Website verwendet HTTP-Cookies, um bestimmte vom Benutzer bereitgestellte Daten zu speichern.
    Was Cookies sind und warum sie verwendet werden, wird im Folgenden erläutert.

    <h4>Was genau sind Cookies?</h4>
    <span class="paragraph">Wenn Sie durch das Internet surfen, verwenden Sie eine Software (Browser). Bekannte Browser sind beispielsweise
      Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge.
      Die meisten Websites speichern kleine Text-Dateien mit Informationen wie z.B. Ihre bevorzugte Sprache oder andere
      persönliche Seiteneinstellungen in Ihrem Browser. Diese Dateien nennt man Cookies.
    Wenn Sie später die gleiche Website erneut besuchen, übermittelt der Browser die gespeicherten
      Cookie-Informationen an den Server zurück. Dadurch können individuelle und für Sie passende Einstellungen benutzt
      werden.
      Cookies können eine Vielzahl von Informationen beinhalten, die den Besucher persönlich identifizierbar machen (wie
      Ihren Namen, Ihre Adresse, Ihre E-Mail-Adresse oder Telefonnummer). Eine Website hat jedoch nur Zugang zu
      persönlichen Daten, die Sie selbst bereitstellen und es kann auch nicht auf andere Dateien auf Ihrem Computer zugreifen.

   Es können auch Cookies von Partner-Webseiten erstellt werden (Drittanbieter-Cookies). Cookies sind keine
      Software-Programme und enthalten keine Viren oder andere "Schädlinge".</span>
    <br/>

<de-cookie-config />

    <h4>Verwendung von Videos</h4>

    Diese Website beinhaltet mindestens ein Plugin von YouTube, gehörig zur Google Inc., ansässig in San
    Bruno/Kalifornien, USA.
    Es werden auf diese Website, Videos über youtube.com aufgerufen.
    Beim Aufruf der betreffenden Seiten werden die IP-Adresse sowie die in Ziffer 4 genannten weiteren Daten
    übertragen und so insbesondere mitgeteilt, welche unserer Internetseiten Sie besucht haben.

    Sobald Sie die Wiedergabe eines eingebundenen Videos durch Anklicken starten, speichert YouTube
    auf Ihrem Gerät auch Cookies. Diese Cookies lassen sich durch entsprechende
    Browsereinstellungen und -erweiterungen verhindern.
    <p>Google/YouTube: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland</p>
    <p>Datenschutzerklärung: <a href="https://policies.google.com/privacy">Link hier</a>,</p>
    <p>Opt-Out: <a href="https://adssettings.google.com/authenticated">Link hier</a>,</p>
    <p> Privacy Shield: <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">Link
      hier</a></p>

    <br/>

    <!--
      <h2>JQuery CDN (jQuery.com)</h2>
      <p>Auf diese Website wird die JavaScript-Bibliothek jQuery verwendet. Diese Bibliothek ermöglicht primär eine moderne Gestaltung der Webseiten. Um die Ladegeschwindigkeit der Seiten zu erhöhen, wird es das CDN (Content Delivery Network) von jQuery verwendet, um diese Bibliothek zu laden. Für diesen Zweck wird es eine Verbindung zum jQuery Server in den USA aufgebaut und Ihre IP-Adresse an die StackPath LLC, den CDN Anbieter von jQuery.com übermittelt.</p>
      <p>Entwickler der jQuery JavaScript Bibliothek ist das jQuery Team der JS Foundation:
        <a href="https://jquery.org/team/">Team</a>,
        <a href="https://js.foundation/contact">Kontakt</a>.</p>
      <p>Dienst wird bereitgestellt durch:</p>
      <p>Anbieter des CDN ist die StackPath LLC, 2021 McKinney Ave, Suite 1100, Dallas, TX 75201, USA.</p>
      <p><a href="https://www.stackpath.com/privacy-statement/#Contact-Information">Informationen zum Datenschutz bei der StackPath LLC</a></p>
      <p><b>Speicherdauer:</b></p>
      Siehe Speicherdauer der Logs unter Informatorische Nutzung weiter oben in dieser Datenschutzerklärung.
      Rechtsgrundlage(n) der Datenverarbeitung:
      Der Dienst wird zur Optimierung unserer Webseiten im eigenen Interesse eingebunden, gem. Art. 6 Abs. 1 lit. f) DSGVO.
      <p>Die Übermittlung Ihrer IP-Adresse an jQuery (StackPath LLC) in die USA erfolgt auf Grundlage des <a href="https://www.privacyshield.gov/EU-US-Framework">EU-US Privacy Shield</a></p>
      <br />
-->
    <h4>BootstrapCDN (MaxCDN)</h4>
    Für eine moderne Gestaltung und Darstellung der Inhalte auf unterschiedlichen Endgeräten, wird es, auf dieser
    Website, die Bootstrap-Technologie verwendet. Um die Ladegeschwindigkeit der Webseiten zu erhöhen, wird es das
    BootstrapCDN (Content Delivery Network) von MaxCDN um sog. Bibliotheken (Sammlungen von technischen Anweisungen) an
    Ihren Browser ausgeliefert. Die Wahrscheinlichkeit ist sehr groß, dass Sie eine dieser Bibliotheken bereits über den
    Besuch einer anderen Webseite vom BootstrapCDN heruntergeladen haben. In diesem Fall kann Ihr Browser auf die im
    Cache gespeicherte Kopie zurückgreifen. Sollte Ihr Browser keine Kopie im Cache gespeichert haben oder aus einem
    anderen Grund die Datei vom BootstrapCDN herunterladen, so wird während der Verbindung zum BootstrapCDN-Server Ihre
    Besuch-Daten an den Anbieter MaxCDN übermittelt.
    Der Dienst wird bereitgestellt durch:
    <p>Anbieter ist die StackPath, LLC, 2021 McKinney Avenue, Suite 1100 Dallas, Texas 75201</p>
    <p><a href="https://www.stackpath.com/legal/master-service-agreement/#pp">Datenschutz bei der StackPath, LLC.</a>
    </p>
    <p><b>Speicherdauer:</b></p>
    Siehe Speicherdauer der Logs unter Informatorische Nutzung weiter oben in dieser Datenschutzerklärung.
    Rechtsgrundlage(n) der Datenverarbeitung:
    Der Dienst wird zur Optimierung unserer Webseiten im eigenen Interesse eingebunden, gem. Art. 6 Abs. 1 lit. f)
    DSGVO.
    <p>Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich StackPath
      (Bootstrap CDN) unter dem <a href="https://www.privacyshield.gov/EU-US-Framework">EU-US Privacy Shield</a>
      unterworfen.</p>
    <br/>

    <h4>Google Web Fonts</h4>
    Auf diese Website werden auch Google Web Fonts verwendet.
    <p>Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, Vertreterin in der EU ist
      die Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland.</p>
    <p><a href="http://www.google.de/policies/privacy/">Datenschutz bei der Google Inc</a></p>
    <p><b>Speicherdauer:</b></p>
    Siehe Speicherdauer der Logs unter Informatorische Nutzung weiter oben in dieser Datenschutzerklärung.
    Rechtsgrundlage(n) der Datenverarbeitung:
    Der Dienst wird zur Optimierung unserer Webseiten im eigenen Interesse eingebunden, gem. Art. 6 Abs. 1 lit. f)
    DSGVO.
    <p>Für die Ausnahmefälle, in denen personenbezogene Daten (Ihre IP-Adresse) in die USA übertragen werden, hat sich
      Google dem <a href="https://www.privacyshield.gov/EU-US-Framework">EU-US Privacy Shield</a> unterworfen.</p>
    <br/>

    <h4>Der Inhaber und Verantwortlicher der Webseite ist:</h4>
    <p><b>Name: </b>San Giovanni, Daniele Maria</p>
    <p><h5><b>Kontaktdaten: </b></h5>
    <p><b>E-Mail: </b> sangiovanni.daniele@gmail.com</p>
    <p><b>Handynummer: </b>+49 0157 84 81 62 21</p>
    <br/><br/><br/>
  </div>
</template>
<script>
import DeCookieConfig from "./DeCookieConfig";
export default {
  name: "GePrivacyContent",
  components: {DeCookieConfig}
}
</script>

<style scoped>
#privacy-content{
  margin-top: 70px;
  padding: 5px;
}
</style>
