<template>
  <div>
    <br><br>
    <VideoComponent :source="video"/>
    <!--<LyricsComponent :song="song" />-->
    <LyricsComponent v-if="this.lyric" :song="song" :lyric="lyric"/>
    <loading-component :show="show" :errmsg="errmsg"/>
  </div>
</template>

<script>
import VideoComponent from "@/components/VideoComponent";
import LyricsComponent from "@/components/LyricsComponent";
import LoadingComponent from "../components/LoadingComponent";
import axios from "axios";

export default {
  name: 'SongView',
  components: {
    VideoComponent,
    LyricsComponent,
    LoadingComponent
  },
  data() {
    return {
      song: null,
      video: null,
      lyric: null,
      show: false,
      errmsg: ''
    }
  },
  mounted() {
    this.song = this.getSong(this.$route.params.id)
    this.video = this.song.video
    this.setLyric(this.$route.params.id)
    //console.log('lyric='+this.lyric)
  },

  methods: {
    getSong(id) {
      let i = this.$store.state.songs.filter(function (list_item) {
        return list_item.id === parseInt(id)
      })
      return i[0]
    },
    setLyric(id) {
      let i = this.$store.state.lyrics.filter(function (list_item) {
        return list_item.id === parseInt(id)
      })
      if (!i.length) {
        this.lyricReq(id)
      } else {
        //console.log(i[0])
        this.lyric = i[0]
      }
    },
    lyricReq(id) {
      this.show = true;
      axios.get(this.$store.getters.apireqlink('lyrics') + '/' + parseInt(id))
          .then(response => {
            this.show = false
            this.lyric = response.data
            this.$store.commit('SET_LYRICS', response.data)
            //console.log(response.data)
          })
          .catch(error => {
            this.show = false
            if (!error.response) {
              // network error
              this.errmsg = this.$store.getters.getLanguageStr('errnetwork')
            }
          })
    }
  }
}
</script>
