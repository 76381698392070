<template>
<div>
  <pdf :src="pdfSource"></pdf>
</div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      pdfSource: './src/assets/Libretto.pdf'
    }
  }
}
</script>

<style scoped>

</style>
