<template>
  <div id="song-list-container">
    <SongListTable :page-id="this.$route.params.id" />
  </div>
</template>

<script>
import SongListTable from "@/components/SongListTable";

export default {
  name: 'SongList',
  components: {
    SongListTable,
  }

}
</script>

<style>
#song-list-container{
  margin-top:60px;
}
</style>

