<template>
    <div class="myform">

      <form @submit.prevent="loginRequest">
        <div id="dropdown-language" right>
          <b-dropdown :text="this.getLang()">
            <b-dropdown-item @click="changelangto('it')">it</b-dropdown-item>
            <b-dropdown-item @click="changelangto('de')">de</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="form-group">
          <input type="number" class="form-control form-control-lg" name="code" :placeholder="this.$store.getters.getLanguageStr('logincode')"
                 v-model="form.coden"/>
        </div>
        <div class="form-group">
          <input type="email" class="form-control form-control-lg" :placeholder="this.$store.getters.getLanguageStr('email')" v-if="adminUser"/>
        </div>
        <div class="form-group">
          <input type="password" class="form-control form-control-lg" :placeholder="this.$store.getters.getLanguageStr('password')" v-if="adminUser"/>
        </div>
        <button type="submit" class="btn btn-secondary btn-lg btn-block">{{ this.$store.getters.getLanguageStr('loginbtn')}}</button>
      </form>
      <loading-component :show="show" :errmsg="errmsg" />
    </div>
</template>

<script>


import axios from "axios";
import LoadingComponent from "./LoadingComponent";
export default {
  name: 'LoginForm',
  components:{LoadingComponent},
  data() {
    return {
      form: {
        coden: '',
      },
      show:false,
      errmsg:''
    }
  },
 beforeCreate() {
    //this.form.coden = this.$store.state.bookcode
    if(this.$cookies.isKey('okcookie') && this.$cookies.isKey('lang')){
      this.$store.commit('SET_DEFAULT_LANG', this.$cookies.get('lang').substr(0,2))
    }
  },
  props: {
    adminUser: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getLang(){
        return this.$store.state.defaultlang
     // return (this.$cookies.isKey('okcookie') && this.$cookies.isKey('lang')) ? this.$cookies.get('lang').substr(0,2) : this.$store.state.defaultlang
    },
    changelangto(value){
      this.$store.commit('updateDefaultLang', value)
      document.title = this.$store.getters.getLanguageStr('sitename')
      document.documentElement.setAttribute('lang', this.$store.state.defaultlang)
      if( this.$cookies.isKey('okcookie')){
       this.$cookies.set('lang', value)
      }
      this.errmsg = ''
    },
    loginRequest() {
      this.show = true;
      this.$store.commit("updateBookCode", this.form.coden)
      if(this.form.coden==='4888' || this.form.coden==='100' || this.form.coden==='150') {
        this.$store.commit("SET_LOGGED")

        axios.get(this.$store.getters.apireqlink('songlist'))
            .then(response => {
              this.show=false
              this.$store.commit('SET_SONGS', response.data)
              this.$router.push('/indice/0')
            }) .catch(error => {
          this.show=false
          if (!error.response) {
            // network error
            this.errmsg = this.$store.getters.getLanguageStr('errnetwork')
          } else {
            //console.log(error.response.data.message);
          }
        })

      }else{
        this.form.coden=''
        this.show=false
        this.errmsg = this.$store.getters.getLanguageStr('errcode')
      }
    },
  }
}
</script>

<style scoped>

.myform{
  margin-top: 20px;
}

#dropdown-language{
  width: 100%;
text-align: right;
  margin-bottom: 15px;
}
</style>

