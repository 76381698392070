<template>
  <div id="mynavbar" class="sticky-top">
    <b-navbar toggleable="sm" type="light" variant="light">
      <b-navbar-brand></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">


      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item active-class="text-dark" @click="getto('/indice/0')">
            {{ this.$store.getters.getLanguageStr('fullindex')}}
          </b-nav-item>
          <b-nav-item active-class="text-dark" @click="getto('/indice/1')">
            {{ this.$store.getters.getLanguageStr('partialindex')}}
          </b-nav-item>
          <b-nav-item active-class="text-dark" @click="getto('/fogliocanti')">
            {{ this.$store.getters.getLanguageStr('songsheet')}}
          </b-nav-item>
          <b-nav-item  @click="logout('/')">
            {{ this.$store.getters.getLanguageStr('logout')}}
          </b-nav-item>
      <!--    <b-nav-item  @click="getto('/liturgia')">
            Sito esterno
          </b-nav-item> -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">

        </b-navbar-nav>
      </b-collapse>

    </b-navbar>

  </div>
</template>

<script>

export default {
  name: 'Navbar',

  methods: {
    getto(link){
      if(this.$route.path !== link) {
        this.$router.push(link)
      }
    },
    logout(link){
this.$store.commit('updateBookCode', '')
      this.$store.commit('updateBookCode', '')

      this.getto(link)
    }
  }
}
</script>

<style>

</style>
