<template>
<div>
  <h4>Wie kann ich Cookies löschen?</h4>
  <span class="paragraph">Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
      Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu
      deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
      zulassen.
    Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
      ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
   <br/><br/>
      <a href="https://support.google.com/chrome/answer/95647?tid=311141511" target="_blank" rel="noopener">Chrome:
        Cookies in Chrome löschen, aktivieren und verwalten</a>
   <br/><br/>

      <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311141511" target="_blank" rel="noopener">Safari:
        Verwalten von Cookies und Websitedaten mit Safari</a>
  <br/><br/>

      <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311141511"
         target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer
        abgelegt haben</a>
 <br/><br/>
      <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311141511"
         target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
   <br/><br/>
      <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311141511" target="_blank"
         rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
  <br/><br/>
    Sollte der verwendete Browser nicht aufgeführt sein, können Sie die Informationen bei Google finden. Suchen Sie zum Beispiel: Cookie-Löschung gefolgt vom Browsernamen.
    </span>
  <div id="cookie-btn"><cookie-remove-btn/></div>
</div>
</template>

<script>
import CookieRemoveBtn from "./CookieRemoveBtn";

export default {
  name: "DeCookieConfig",
  components: {CookieRemoveBtn}
}
</script>

<style scoped>
#cookie-btn{
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
</style>
