import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '@/views/Home.vue'
import Login from '@/views/SimpleGuestLogin.vue'
import Register from '@/views/Register.vue'
//import Index0 from "@/views/Index0"
//import Index1 from "@/views/Index1"
import SongSheet from "@/views/SongSheet"
import SongView from "@/views/SongView"
import SongList from "@/views/SongList";
import ExternalPage from "../components/ExternalPage";
import VideoTest from "../views/VideoTest";
import Store from "../store";

Vue.use(VueRouter, Store)

function guardMyroute(to, from, next) {
if(Store.state.login.bookcode !== '') {
    next()
}else{
    next('/login')
}

}

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/indice/:id',
        name: 'Index0',
        component: SongList,
        beforeEnter : guardMyroute
    },
    {
        path: '/fogliocanti',
        name: 'SongSheet',
        component: SongSheet,
        beforeEnter : guardMyroute,
    },
    {
        path: '/test',
        name: 'VideoTest',
        component: VideoTest,
        beforeEnter : guardMyroute,
    },
    {
        path: '/login',
        name: 'SimpleGuestLogin',
        component: Login
    },
    {
        path: '/registrazione',
        name: 'Register',
        component: Register
    },
    {
        path: '/liturgia',
        name: 'ExternalPage',
        component: ExternalPage,
        beforeEnter : guardMyroute,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        beforeEnter : guardMyroute,
    },
    {
        path: '/view/:id',
        name: 'SongView',
        component: SongView,
        beforeEnter : guardMyroute,
    }
]

const router = new VueRouter({
    routes
})

export default router


