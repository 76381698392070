<template>
<div>
  <h4> Come posso eliminare i cookie? </h4>
  <p> Sei tu a decidere come e se desideri utilizzare i cookie, indipendentemente da quale servizio o quale
    Sito web essi provengano, hai sempre la possibilità di cancellarli, accettarli (anche solo parzialmente) o
    disattivarli. Ad esempio, puoi bloccare i cookie di terze parti, ma tutti gli altri cookie
    consentirli. </p>
  <p> Se vuoi scoprire quali cookie sono stati memorizzati nel tuo browser, come modificare le impostazioni dei cookie
    o eliminarli, puoi trovare ciò nelle impostazioni del tuo browser: </p>
  <br/><br/>
    <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener">Chrome: Gestisci i cookie e i dati dei siti web con Chrome</a>
  <br/><br/>
    <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noopener">Safari:
      Safari: Gestisci i cookie e i dati dei siti web con Safari</a>
  <br/><br/>
    <a href="https://support.mozilla.org/it/kb/Eliminare%20i%20cookie"
       target="_blank" rel="noopener">Firefox: Gestisci i cookie e i dati dei siti web con Firefox</a>
  <br/><br/>
    <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies"
       target="_blank" rel="noopener">Internet Explorer: Gestisci i cookie e i dati dei siti web con Internet Explorer</a>
  <br/><br/>
    <a href="https://support.microsoft.com/it-it/help/4027947/windows-delete-cookies" target="_blank"
       rel="noopener">Microsoft Edge: Gestisci i cookie e i dati dei siti web con Microsoft Edge</a>
  <br/><br/>
  Se il Browser utilizzato non è in elenco, è possibile trovare le informazioni con google. Cercare per esempio: Cancellazione cookie seguito dal nome del browser.
<!--<div id="cookie-btn"><cookie-remove-btn/></div>-->
</div>
</template>

<script>
//import CookieRemoveBtn from "./CookieRemoveBtn";
export default {
  name: "ItCookieConfig",
 // components: {CookieRemoveBtn}
}
</script>

<style scoped>
#cookie-btn{
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
</style>
