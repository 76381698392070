<template>
<div class="loading">
  <span class="text-danger">{{ errmsg }}</span>
  <b-overlay :show="show" rounded="sm" />
</div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    show:{
      type: Boolean,
      default: true
    },
    errmsg:{
      type: String,
      default: () =>''
    }
  }
}
</script>

<style scoped>
.loading{
margin-top:30px;
}
</style>
