<template>
  <div class="footer">
   <small>&copy; Copyright 2021 - <privacy-link /></small>
  </div>
</template>

<script>
import PrivacyLink from "./privacy/PrivacyLink";
export default {
  name: 'Footer',
  components: {PrivacyLink}
}
</script>

<style scoped>
.footer{
  position: fixed;
  bottom:0;
  left:0;
  background-color: #ccc;
  width:100%;
  text-align: center;
}
</style>
