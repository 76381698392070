<template>
  <div id="simple-login">
    <LoginForm :admin-user="false" />
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'SimpleGuestLogin',
  components: {
    LoginForm,
  },
};
</script>

<style scoped>
#simple-login{
  width: 70%;
  position: absolute;
  top: 15%;
  left: 15%;
}
</style>
