<template>
  <div>
<br><br><br>
    <h2>{{ this.$store.getters.getLanguageStr('songsheet')}}</h2>

      <div  v-for="lyric in this.selectedlyrics" :key="lyric.id"  >
      <LyricsComponent :song="getSong(lyric.id)" :lyric="lyric" />
      <hr>
    </div>

    <loading-component :show="show" :errmsg="errmsg" />

  </div>
</template>

<script>
import LyricsComponent from "@/components/LyricsComponent";
import LoadingComponent from "../components/LoadingComponent";
import axios from "axios";
export default {
  name: 'SongSheet',
  components: {
    LyricsComponent,
    LoadingComponent
  },
  data() {
    return {
      selectedlyrics: this.$store.state.selectedlyrics,
      errmsg: '',
      show: false
    }
  },
  mounted() {
    if (this.$store.state.login.isabookselected === false) {
      this.$router.push('/login')
    }
    if(!this.$store.state.selectedlyrics.length){
      this.getLyrics()
    }
  },
  methods: {
    getLyrics(){
      axios.get(this.$store.getters.apireqlink('lyrics'))
          .then(response => {
            this.selectedlyrics = response.data
            this.$store.commit('SET_LYRICS', response.data)
            this.$store.commit('SET_SELECTEDLYRICS', response.data)

            this.show=false
          }) .catch(error => {
        this.show=false
        if (!error.response) {
          // network error
          this.errmsg = this.$store.getters.getLanguageStr('errnetwork')
        }
      })
    },
    getSong(id) {
      let i = this.$store.state.songs.filter(function (list_item) {
        return list_item.id === parseInt(id)
      })
      return i[0]
    }

  }
}
</script>
