<template>
  <div id="nav2">
    <b-button-group>
      <b-button class="mybtn" variant="outline-secondary" @click="getto('/indice/0')">
        <b-icon icon="menu-button-wide-fill"></b-icon><br />
        <small> {{ this.$store.getters.getLanguageStr('fullindex')}} </small>
      </b-button>
      <b-button class="mybtn" variant="outline-secondary" @click="getto('/indice/1')">
        <b-icon icon="menu-app-fill"></b-icon><br />
        <small> {{ this.$store.getters.getLanguageStr('partialindex')}} </small>
      </b-button>
      <b-button class="mybtn" variant="outline-secondary" @click="getto('/fogliocanti')">
        <b-icon icon="file-text-fill"></b-icon><br />
        <small> {{ this.$store.getters.getLanguageStr('songsheet')}} </small>
      </b-button>
      <b-button class="mybtn" variant="outline-secondary" @click="logout('/')">
        <b-icon icon="lock-fill"></b-icon><br />
        <small> {{ this.$store.getters.getLanguageStr('logout')}} </small>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>

export default {
  name: 'Navbar',

  methods: {
    getto(link){
      if(this.$route.path !== link) {
        this.$router.push(link)
      }
    },
    logout(link){
      this.$store.commit('updateBookCode', '')
      this.$store.commit('updateBookCode', '')

      this.getto(link)
    }
  }
}
</script>

<style scoped>
#nav2{
  top:0px;
  position: fixed;
  background-color: #bbbbbb;
  outline: none !important;
  width:100%;
}
.mybtn{
  padding:5px;
  line-height: 1em;
}
</style>
